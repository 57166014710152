import styled from 'styled-components/native';

import { ButtonSizes, UniversalActionButton } from 'components/UniveralButtons';
import { sleepioColors } from 'config/getThemeForProducts/themes/sleepio';

import { DOMAIN } from './constants';

export const WidgetWrapperCommon = styled.View`
  flex-direction: row;
  background-color: ${sleepioColors.progressColor};
  width: 90%;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
`;
WidgetWrapperCommon.displayName = `${DOMAIN}.WidgetWrapperCommon`;

export const Button = styled(UniversalActionButton).attrs({
  size: ButtonSizes.Mini,
})`
  font-size: 16px;
  font-weight: 500;
`;
Button.displayName = `${DOMAIN}.Button`;

export const ButtonWrapper = styled.View`
  width: 30%;
  justify-content: flex-end;
  flex-direction: row;
`;
ButtonWrapper.displayName = `${DOMAIN}.ButtonWrapper`;

export const HeadingWrapper = styled.View`
  width: 70%;
`;
HeadingWrapper.displayName = `${DOMAIN}.HeadingWrapper`;

export const HeadingTextCommon = styled.Text`
  color: ${sleepioColors.altDarkBlue};
  font-size: 20px;
  flex-wrap: wrap;
  font-weight: 500;
`;
HeadingTextCommon.displayName = `${DOMAIN}.HeadingText`;
