import React, { ReactElement, ReactNode, useCallback } from 'react';
import {
  AccessibilityProps,
  Button,
  LayoutRectangle,
  View,
} from 'react-native';
import { complement, filter } from 'ramda';

import {
  DateResponseOption,
  DatesAndTimesValueObject,
  Question,
  ResponseOption,
  ResponseOptionInputVariant,
  ResponseOptionValue,
  ResponseTypeType,
  TextResponseOption,
} from '@bighealth/types';
import { ResponseInputComponentType } from '@bighealth/types/src/scene-components/sleep-diary/entry-form';
import { AssetEntity } from '@bighealth/types/src/services/Asset';

import { TextTypes } from 'common/constants/enums';
import { QuizAttribute } from 'components/constants';
import { ResponseInputState } from 'components/forms/ResponseOptions/ResponseInput/types';
import { Value } from 'components/forms/types';
import {
  DatePicker,
  DateTimePicker,
  TimePicker,
} from 'components/generic-question/DateTimePickers';
import {
  GenericInput,
  GenericInputTypes,
} from 'components/generic-question/GenericInput';
import { GenericInputButton } from 'components/generic-question/GenericInputButton';
import { HorizontalScaleLabels } from 'components/generic-question/HorizontalScale';
import {
  getIsMultiSelect,
  getIsToggleable,
  onInputValueChange,
} from 'components/generic-question/InputsAsArray/helpers';
import { ButtonContainer } from 'components/generic-question/QuestionsLayout';
import { Row } from 'components/layout/Grid';
import { ErrorText } from 'components/Text/ErrorText';
import HelperText from 'components/Text/HelperText';
import { WarningText } from 'components/Text/WarningText';
import { roles } from 'cross-platform/utils/roleProps';
import { createTimeDate } from 'lib/createTimeDate';
import { MINUTE } from 'lib/durations';
import { useLatestFocusedQuestion } from 'lib/question-response/useLatestFocusedQuestion';
import { qaConfigFlags } from 'lib/showQAMenu/qaConfigFlags';
import { stringify } from 'lib/stringify';
import { throwIfNotDateOrUndefined } from 'lib/type-guarded';

import { Dropdown, DropdownItem, DropdownOption } from '../Dropdown';
import { DropdownDurationPicker } from '../DropdownDurationPicker';
import { DropdownTimePicker } from '../DropdownTimePicker';
import { TextAreaInput } from '../GenericInput/TextAreaInput/TextAreaInput';
import { HorizontalScaleItem, ItemLocation } from '../HorizontalScale';
import { PhoneNumber } from '../PhoneNumber/PhoneNumber';
import { SelectHorizontalItem } from '../SelectHorizontal/SelectHorizontalItem';
import { SelectHorizontalTime } from '../SelectHorizontalTime';

import { getQuizFromAttempts } from './helpers/getQuizFromAttempts';
import { injectHideEmptyOption } from './helpers/injectHideEmptyOption';
import {
  HorizontalWrapper,
  InputPadding,
  PreTextContainer,
  QuestionPreText,
  QuestionTitle,
  QuestionTitleContainer,
  ResponsePreText,
} from './components';
import { SelectHorizontalScrollView } from './styled';

type Props = {
  // IDEA (92acef87) Make non optional AC: which one?
  component: ResponseInputComponentType;
  dataPath: string;
  displayText?: string;
  id: number;
  inputValue: Value;
  isSelected: boolean;
  isToggleable?: boolean;
  label?: string;
  location: ItemLocation;
  max?: Value;
  min?: Value;
  interval?: number;
  onChangeItem?: (val: string) => void;
  onSelect?: (val?: string) => void;
  onSetValue?: (val?: Value) => void;
  quiz?: QuizAttribute;
  type: ResponseTypeType;
  value: ResponseOptionValue;
  highlight?: boolean;
  hideEmptyOption?: boolean;
  state?: ResponseInputState;
  variant: ResponseOptionInputVariant;
  placeholderText?: string;
  image?: AssetEntity;
  scrollToTarget?: (layoutRectangle: LayoutRectangle) => void;
  initialVisibleValue?: ResponseOptionValue | DatesAndTimesValueObject;
  defaultCountry?: string;
  schedule_delay_in_minutes?: number;
  isMultiSelect?: boolean;
  onKeyDown?: any;
  accessibilityProps?: AccessibilityProps;
  questionId?: string;
};

const isOptionInput = (option: ResponseOption | Props): boolean =>
  option.value === '$input';

const AnyInput = (props: Props): ReactElement => {
  const {
    questionId,
    component,
    dataPath,
    displayText,
    inputValue,
    isSelected = false,
    isToggleable = true,
    location,
    onSelect,
    onSetValue,
    quiz,
    type,
    label,
    highlight,
    state,
    hideEmptyOption,
    variant,
    placeholderText,
    image,
    initialVisibleValue,
    defaultCountry,
    scrollToTarget,
    id,
    isMultiSelect = false,
    onKeyDown,
    accessibilityProps,
  } = props;

  const handleOnValueChange = useCallback(
    (newValue: string) => onInputValueChange(newValue, type, onSetValue),
    [type, onSetValue]
  );

  let { max, min } = props;
  const { interval } = props;
  if (isOptionInput(props)) {
    switch (component) {
      case 'DropdownTimePicker':
      case 'SelectHorizontalTime':
      case 'DateTimePicker':
      case 'DatePicker':
      case 'TimePicker':
        // @TODO: Remove date conversion.
        // WHY: because deserialising from disk
        // was causing errors in date format.
        // Remove this.
        // WHEN we revisit with QR state fixes.
        // PG-741 might be related
        if (typeof min === 'string') {
          min = new Date(min);
        }
        if (typeof max === 'string') {
          max = new Date(max);
        }

        if (
          !(
            (typeof min === 'undefined' || min instanceof Date) &&
            (typeof max === 'undefined' || max instanceof Date)
          )
        ) {
          if (
            isNaN(min?.valueOf() as number) ||
            isNaN(max?.valueOf() as number)
          ) {
            throw TypeError(
              `Expected min & max to be Date or undefined, instead got min: ${min} & max: ${max}`
            );
          }
        }
        if (
          inputValue instanceof Date ||
          inputValue === null ||
          typeof inputValue === 'undefined'
        ) {
          switch (component) {
            case 'DateTimePicker':
              return (
                <InputPadding>
                  {qaConfigFlags.getValue('Debug Clear for Date Pickers') ? (
                    <Button
                      title="Debug: clear"
                      onPress={() => onSetValue?.(undefined)}
                    />
                  ) : null}
                  <DateTimePicker
                    minDate={min as Date}
                    maxDate={max as Date}
                    onDateChange={(date: Date | null): void => {
                      onSetValue?.(date || undefined); //  IDEA use null?
                    }}
                    schedule_delay_in_minutes={props.schedule_delay_in_minutes}
                    date={inputValue || null}
                  />
                </InputPadding>
              );
            case 'DatePicker':
              return (
                <InputPadding>
                  {qaConfigFlags.getValue('Debug Clear for Date Pickers') ? (
                    <Button
                      title="Debug: clear"
                      onPress={() => onSetValue?.(undefined)}
                    />
                  ) : null}
                  <DatePicker
                    minDate={(min as Date) || undefined}
                    maxDate={(max as Date) || undefined}
                    onDateChange={(date: Date | null): void => {
                      onSetValue?.(date || undefined); //  IDEA use null?
                    }}
                    date={inputValue || null}
                  />
                </InputPadding>
              );
            case 'TimePicker':
              return (
                <InputPadding>
                  {qaConfigFlags.getValue('Debug Clear for Date Pickers') ? (
                    <Button
                      title="Debug: clear"
                      onPress={() => onSetValue?.(undefined)}
                    />
                  ) : null}
                  <TimePicker
                    onDateChange={(date: Date | null): void => {
                      if (onSetValue) {
                        if (date) {
                          onSetValue(createTimeDate(date));
                        } else {
                          onSetValue?.(date);
                        }
                      }
                    }}
                    date={inputValue || null}
                  />
                </InputPadding>
              );
            case 'SelectHorizontalTime':
              return (
                <InputPadding>
                  <SelectHorizontalTime
                    min={throwIfNotDateOrUndefined(min, 'min')}
                    max={throwIfNotDateOrUndefined(max, 'max')}
                    selectedValue={inputValue === null ? undefined : inputValue}
                    onValueChange={onSetValue}
                    highlight={highlight}
                    state={state}
                    initialVisibleValue={throwIfNotDateOrUndefined(
                      initialVisibleValue,
                      'initialVisibleValue'
                    )}
                    scrollToTarget={scrollToTarget}
                    interval={interval}
                  />
                </InputPadding>
              );
            case 'DropdownTimePicker':
              // NOTE Parent takes care of onValueChange handler on native
              return (
                <InputPadding>
                  <DropdownTimePicker
                    min={(min as Date) || undefined}
                    max={(max as Date) || undefined}
                    selectedValue={inputValue === null ? undefined : inputValue}
                    onValueChange={onSetValue}
                    highlight={highlight}
                    hideEmptyOption={hideEmptyOption}
                    state={state}
                    interval={interval}
                  />
                </InputPadding>
              );
          }
        } else {
          // For all other "$input" that is not date..
          throw TypeError(
            `Date/Time Pickers expected instanceof Date or null, instead got inputValue: ${typeof inputValue} "${inputValue}" (${typeof inputValue}) ${stringify(
              props
            )}`
          );
        }
        break;
      case 'PhoneNumberInput':
        return (
          <InputPadding>
            <PhoneNumber
              value={String(inputValue ?? '')}
              onValueChange={handleOnValueChange}
              onClick={isToggleable ? onSelect : undefined}
              defaultCountry={defaultCountry}
            />
          </InputPadding>
        );
      case 'DropdownDurationPicker':
        // NOTE Parent takes care of onValueChange handler on native
        if (typeof min !== 'number' && typeof min !== 'undefined') {
          throw TypeError(
            `Expected min to be of type "number" or "undefined", instead got ${min} (${typeof min})`
          );
        }
        if (typeof max !== 'number' && typeof max !== 'undefined') {
          throw TypeError(
            `Expected max to be of type "number" or "undefined", instead got ${max} (${typeof max})`
          );
        }
        if (
          inputValue !== '' &&
          typeof inputValue !== 'number' &&
          typeof inputValue !== 'undefined'
        ) {
          throw TypeError(
            `Expected inputValue to be of type "number" or "undefined", instead got ${inputValue} (${typeof inputValue})`
          );
        }
        return (
          <InputPadding>
            <DropdownDurationPicker
              min={min || undefined}
              max={max || undefined}
              selectedValue={inputValue}
              onValueChange={onSetValue}
              highlight={highlight}
              hideEmptyOption={hideEmptyOption}
            />
          </InputPadding>
        );
      default:
        if (
          typeof inputValue === 'string' ||
          typeof inputValue === 'number' ||
          typeof inputValue === 'undefined'
        ) {
          return (
            <InputPadding>
              {variant === 'textArea' ? (
                <TextAreaInput
                  value={String(
                    typeof inputValue === 'undefined' ? '' : String(inputValue)
                  )}
                  onValueChange={newValue =>
                    onInputValueChange(newValue, type, onSetValue)
                  }
                  onClick={isToggleable ? onSelect : undefined}
                  placeholderText={placeholderText}
                />
              ) : (
                <GenericInput
                  dataPath={dataPath}
                  isSelected={isSelected}
                  value={String(
                    typeof inputValue === 'undefined' ? '' : String(inputValue)
                  )}
                  onValueChange={newValue =>
                    onInputValueChange(newValue, type, onSetValue)
                  }
                  onClick={isToggleable ? onSelect : undefined}
                  onPress={isToggleable ? onSelect : undefined}
                  placeholderText={placeholderText}
                  type={type as GenericInputTypes}
                  questionId={questionId}
                  id={id.toString()}
                  // highlight={highlight} // IDEA add highlight WHEN its needed
                />
              )}
            </InputPadding>
          );
        } else {
          throw TypeError(
            `TextInput & NumberInput expected type string, number or undefined, instead got ${typeof inputValue}`
          );
        }
    }
  } else {
    // Not option input
    switch (component) {
      case 'Dropdown':
        // Parent takes care of handler
        return (
          <DropdownOption
            value={inputValue}
            displayText={displayText || `${inputValue}`}
          />
        );
      case 'SelectHorizontal':
        return (
          <SelectHorizontalItem
            displayText={displayText || String(inputValue)}
            value={inputValue}
            selected={isSelected || !!quiz}
            onSelect={(): void => {
              onSelect?.();
            }}
            image={image}
            location={location}
            // highlight={highlight} // TODO add highlight WHEN its needed
          />
        );
      case 'HorizontalScale':
        return (
          <HorizontalScaleItem
            displayText={displayText || String(inputValue)}
            value={inputValue}
            selected={isSelected || !!quiz}
            onSelect={(): void => {
              onSelect?.();
            }}
            location={location}
            quiz={quiz}
            accessibilityLabel={accessibilityProps?.accessibilityLabel}
            // highlight={highlight} // TODO add highlight WHEN its needed
          />
        );
    }
  }
  return (
    <InputPadding>
      <GenericInputButton
        isSelected={isSelected || !!quiz}
        quiz={quiz}
        onClick={isToggleable ? onSelect : undefined}
        onPress={isToggleable ? onSelect : undefined}
        label={label}
        highlight={highlight}
        id={id.toString()}
        isMultiSelect={isMultiSelect}
        onKeyDown={onKeyDown}
        accessibilityLabel={displayText}
        questionId={questionId}
      >
        {displayText}
      </GenericInputButton>
    </InputPadding>
  );
};

type MapControlsToResponseOptionsTypes = InputsAsArrayProps & {
  filterFn?: (value: ResponseOption) => boolean;
  scrollToTarget?: (layoutRectangle: LayoutRectangle) => void;
  onKeyDown?: any;
};

const mapControlsToResponseOptions = ({
  questionProps,
  selection,
  correctSelection = [],
  incorrectSelection = [],
  attempts,
  onSelect,
  onSetValue,
  component,
  isQuizCompletedComplete = false,
  filterFn = (): boolean => true,
  highlight,
  state,
  scrollToTarget,
  schedule_delay_in_minutes,
  onKeyDown,
}: MapControlsToResponseOptionsTypes): ReactNode[] =>
  questionProps.response_config.response_options
    // optional filter to allow for grouping
    .filter(filterFn)
    // render inputs
    .map(
      (
        option: ResponseOption,
        index: number,
        arr: ResponseOption[]
      ): ReactNode => {
        const { id, value, display_text: displayText, label, image } = option;
        const selected: DropdownItem | undefined = selection.find(
          e => e.id === id
        );
        if (typeof selected === 'undefined') {
          throw ReferenceError(
            `selected can't be undefined: looking of id (${id}) in selection (${selection
              .map(e => e.id)
              .join(',')})`
          );
        }

        const isToggleable = getIsToggleable(questionProps);
        const isMultiSelect = getIsMultiSelect(questionProps);
        const quiz =
          typeof attempts === 'undefined'
            ? undefined
            : getQuizFromAttempts(
                id,
                attempts,
                selected,
                isQuizCompletedComplete,
                incorrectSelection,
                correctSelection,
                questionProps.response_config.correct_response_ids
              );
        const asDateResponseOption = option as DateResponseOption;

        const responsePreText = questionProps.response_pre_text;
        const questionTitle = questionProps.question_title;
        const questionPreText = questionProps.question_pre_text;
        const labelText = responsePreText || questionTitle || questionPreText;
        const titleQuestionId = questionProps.id?.toString() || '';
        return (
          <AnyInput
            questionId={titleQuestionId}
            key={`${id}.${value}.${displayText}`}
            dataPath={`question:${questionProps.semantic_id} option:${id}`}
            id={id}
            state={state}
            inputValue={selected.value}
            isSelected={!!selected.isSelected}
            isToggleable={isToggleable}
            max={asDateResponseOption?.max_response}
            min={asDateResponseOption?.min_response}
            interval={
              asDateResponseOption?.interval_time_minutes
                ? asDateResponseOption.interval_time_minutes * MINUTE
                : undefined
            }
            type={questionProps.response_type}
            value={
              component === 'Dropdown' && typeof value === 'number'
                ? `${value}`
                : value
            }
            quiz={quiz}
            displayText={displayText || ''}
            label={
              component === 'Dropdown' ? displayText || label : label
              // NOTE AC: For native, the immediate-child's label is used.
              // The label of sub-children (e.g. DropdownOption) are IGNORED ("Duck-typing")
            }
            onSelect={(): void => {
              onSelect(option);
            }}
            component={component}
            onSetValue={(inputValue?: string | Date | null | number): void => {
              onSetValue(option, inputValue);
            }}
            location={
              index === 0
                ? ItemLocation.START
                : index === arr.length - 1
                ? ItemLocation.END
                : ItemLocation.MIDDLE
            }
            highlight={highlight}
            hideEmptyOption={questionProps.response_config.hide_empty_option}
            variant={(option as TextResponseOption)?.input_variant ?? 'input'}
            placeholderText={(option as TextResponseOption)?.placeholder_text}
            image={image}
            scrollToTarget={scrollToTarget}
            schedule_delay_in_minutes={schedule_delay_in_minutes}
            initialVisibleValue={
              questionProps.response_config?.initial_visible_response_value
            }
            defaultCountry={questionProps.response_config?.default_country}
            isMultiSelect={isMultiSelect}
            onKeyDown={onKeyDown}
            accessibilityProps={{
              accessibilityLabel: labelText,
            }}
          />
        );
      }
    );

const MapControlsToResponseOptions = (
  props: MapControlsToResponseOptionsTypes
): ReactElement => <>{mapControlsToResponseOptions(props)}</>;

export type InputsAsArrayProps = {
  questionProps: Question;
  component: ResponseInputComponentType;
  selection: DropdownItem[];
  correctSelection?: ResponseOption['id'][];
  incorrectSelection?: ResponseOption['id'][];
  attempts?: ResponseOption[][];
  error?: string;
  hint?: string;
  warning?: string;
  highlight?: boolean;
  isTouched?: boolean;
  onSelect: (option: ResponseOption) => void;
  isQuizCompletedComplete: boolean;
  onSelectValue: (selectedValue: string | Date | null) => void; // For Dropdown only
  onSetValue: (option: ResponseOption, inputValue?: Value) => void;
  onBlur: () => void;
  state?: ResponseInputState;
  image?: AssetEntity;
  schedule_delay_in_minutes?: number;
  onKeyDown?: any;
};

export const InputsAsArray = (props: InputsAsArrayProps): ReactElement => {
  const { currentFocusedQuestionId } = useLatestFocusedQuestion();
  const selectedOptions = filter(e => !!e.isSelected, props.selection);
  const responsePreText = props.questionProps.response_pre_text;
  const questionTitle = props.questionProps.question_title;
  const questionPreText = props.questionProps.question_pre_text;
  const responseOptions = props.questionProps.response_config.response_options;
  const dropdownLabelText = responsePreText || questionTitle || questionPreText;
  const isHorizontalScaleComponent = props.component === 'HorizontalScale';
  // Remove injectHideEmptyOption
  // when Staff Web will have an ability to set hide_empty_option

  const questionProps = injectHideEmptyOption(props.questionProps);

  const isInFocus = currentFocusedQuestionId === props.questionProps.id;
  const questionTitleId = props.questionProps.id?.toString();
  return (
    <ButtonContainer {...roles.pass(props)}>
      <View
        {...roles(`component:${props.component}`, {
          role: isHorizontalScaleComponent ? 'radiogroup' : undefined,
        })}
      >
        {questionPreText ? (
          <PreTextContainer>
            <QuestionPreText accessibilityRole={'header'} aria-level={6}>
              {questionPreText}
            </QuestionPreText>
          </PreTextContainer>
        ) : null}
        {questionTitle ? (
          <QuestionTitleContainer>
            <QuestionTitle
              {...roles(`QuestionTitle`)}
              htmlFor={`content-question-${props.questionProps.id}`}
              id={questionTitleId}
            >
              {questionTitle}
            </QuestionTitle>
          </QuestionTitleContainer>
        ) : null}
        {responsePreText ? (
          <PreTextContainer>
            <ResponsePreText>{responsePreText}</ResponsePreText>
          </PreTextContainer>
        ) : null}
        {props.component === 'Dropdown' ? (
          <InputPadding>
            <Dropdown
              id={`content-question-${props.questionProps.id}`}
              value={selectedOptions}
              onValueChange={props.onSelectValue}
              onBlur={props.onBlur}
              options={responseOptions}
              highlight={props?.highlight}
              hideEmptyOption={questionProps.response_config.hide_empty_option}
              // Change this to Listbox after React Native 0.7X upgrade
              accessibilityRole={'combobox'}
              accessibilityLabel={dropdownLabelText}
            >
              {mapControlsToResponseOptions({
                ...props,
                questionProps,
                filterFn: complement(isOptionInput),
              })}
            </Dropdown>
          </InputPadding>
        ) : props.component === 'SelectHorizontal' ? (
          <SelectHorizontalScrollView>
            <MapControlsToResponseOptions
              {...props}
              questionProps={questionProps}
              filterFn={complement(isOptionInput)}
            />
          </SelectHorizontalScrollView>
        ) : props.component === 'SelectHorizontalTime' ? (
          <InputPadding>
            <Row flexGrow={0} flexShrink={1} flexBasis={'auto'}>
              <HorizontalWrapper isScroll={false}>
                {(wrapperArgs: {
                  scrollToTarget: React.Dispatch<
                    React.SetStateAction<LayoutRectangle | null>
                  >;
                }) => (
                  <MapControlsToResponseOptions
                    {...props}
                    scrollToTarget={wrapperArgs?.scrollToTarget}
                    questionProps={questionProps}
                  />
                )}
              </HorizontalWrapper>
            </Row>
          </InputPadding>
        ) : isHorizontalScaleComponent ? (
          <>
            <InputPadding>
              <Row>
                <MapControlsToResponseOptions
                  {...props}
                  questionProps={questionProps}
                  filterFn={complement(isOptionInput)}
                />
              </Row>
              <Row>
                <HorizontalScaleLabels
                  {...props}
                  questionProps={questionProps}
                />
              </Row>
            </InputPadding>
            <MapControlsToResponseOptions
              {...props}
              filterFn={isOptionInput}
              questionProps={questionProps}
            />
          </>
        ) : (
          <MapControlsToResponseOptions
            {...props}
            questionProps={questionProps}
            selection={props.selection}
          />
        )}
        {props.error && props.isTouched && !isInFocus ? (
          <ErrorText>{props.error}</ErrorText>
        ) : null}
        {props.warning ? <WarningText>{props.warning}</WarningText> : null}
        {props.error && props.isTouched && isInFocus ? (
          <HelperText presentationType={TextTypes.HINT}>
            {props.hint ? props.hint : props.error}
          </HelperText>
        ) : null}
      </View>
    </ButtonContainer>
  );
};
