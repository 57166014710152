import React from 'react';
import styled from 'styled-components/native';

import { useProgressReviewRedirect } from 'components/ProgressReview/hooks/useProgressReviewRedirect';
import { roles } from 'cross-platform/utils/roleProps';
import { WithState } from 'sleepio/components/screens/SessionsScreen/components/WithState';

import { Loading } from './components/Loading';
import { useSessionsDataQuery } from './hooks/useQuerySessionsData';

const Wrapper = styled.View`
  flex-grow: 1;
`;
/**
 * SessionScreen: Handles all network logic
 */
const WithNetworkAndState = (): React.ReactElement => {
  const { isLoading, data } = useSessionsDataQuery();

  useProgressReviewRedirect(data?.result);

  return (
    <Wrapper>
      {isLoading ? (
        <Loading {...roles('Loading')} />
      ) : typeof data?.result !== 'undefined' ? (
        <WithState {...roles('Data')} data={data?.result} />
      ) : null}
    </Wrapper>
  );
};

export { WithNetworkAndState };
