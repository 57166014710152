import React, { ReactElement, useContext } from 'react';
import { View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components/native';

import { ProductReferences } from 'common/constants/enums';
import { ScreenResponsiveLayoutContext } from 'components/ResponsiveLayout';
import { useTransformContentStyleVertical } from 'components/ResponsiveLayout';
import { Text } from 'components/sceneset-components/Text';
import { PLATGEN_FF_MFA_ENABLED } from 'config/envVars';
import { useHistory } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { CloseIcon } from 'daylight/components/icons';
import {
  useLazyQueryLogout,
  useQueryDaylightSettings,
} from 'lib/api/reactQueryHelpers';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';
import { isDevMode } from 'lib/isDevMode';
import { showQAMenu } from 'lib/showQAMenu';
import { getNotificationFeatureFlag } from 'state/notifications/selectors';

import {
  ItemsContainer,
  MenuItem,
  MenuPortal,
  NameContainer,
  ShadowedMenuContainer,
  Stretcher,
} from './CollapsedNav.styled';
import { FooterCollapsedNav } from './FooterCollapsedNav';
import {
  settingsConfig,
  SettingsConfigNotificationsKey,
  SettingsMfaDisabledKey,
  SettingsMfaEnabledKey,
} from './settingsConfig';

export type MenuOptionParam = {
  notifFeatureFlag: boolean;
  mfaFeatureFlag: boolean;
  mfaEnabled: boolean;
};

export type SettingsConfigItems = typeof settingsConfig.items;

/**
 * Returns a list of options to show on the navigation menu
 * if the user has mfa enabled, we would want to go into the mfa flow to disable it
 * @param params - menu options
 */
const getMenuOptions = (params: MenuOptionParam): SettingsConfigItems => {
  const settingsConfigItems = settingsConfig.items.reduce((agg, curr) => {
    switch (curr.key) {
      case SettingsConfigNotificationsKey: {
        return params.notifFeatureFlag ? [...agg, curr] : agg;
      }
      case SettingsMfaEnabledKey: {
        return !params.mfaEnabled && params.mfaFeatureFlag
          ? [...agg, curr]
          : agg;
      }
      case SettingsMfaDisabledKey: {
        return params.mfaEnabled && params.mfaFeatureFlag
          ? [...agg, curr]
          : agg;
      }
      default:
        return [...agg, curr];
    }
  }, [] as SettingsConfigItems);
  return settingsConfigItems;
};

export const CollapsedNav = ({
  setSettingsScreen,
}: {
  setSettingsScreen: () => void;
}): ReactElement => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const { top } = useSafeArea();
  const transformStyle = useTransformContentStyle();
  const transformVerticalStyle = useTransformContentStyleVertical();
  const FF_NOTIFICATIONS_ON = useSelector(getNotificationFeatureFlag);

  const { data } = useQueryDaylightSettings();
  const logout = useLazyQueryLogout();
  const history = useHistory();

  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);

  // NOTE(Luca): maybe we want to handle the error differently?

  const { account, mfa_enabled } = data?.result || {};

  const onHelpPress = async (): Promise<void> => {
    showQAMenu(ProductReferences.DAYLIGHT, history, dispatch);
  };

  const onLogoutPress = async (): Promise<void> => {
    await logout();
  };

  const settingsOptions = getMenuOptions({
    notifFeatureFlag: FF_NOTIFICATIONS_ON ? FF_NOTIFICATIONS_ON : false,
    mfaFeatureFlag: PLATGEN_FF_MFA_ENABLED,
    mfaEnabled: mfa_enabled ? mfa_enabled : false,
  });

  return (
    <MenuPortal {...roles('DaylightCollapsedNav')}>
      <Stretcher style={{ width: screenWidth }}>
        <ShadowedMenuContainer>
          <NameContainer style={{ paddingTop: top, height: '25%' }}>
            <Text
              {...roles('DaylightCollapsedNavName')}
              style={{
                color: theme.color.text.primary,
                fontWeight: 700,
                fontSize: transformStyle(21),
                padding: transformStyle(10),
                paddingHorizontal: transformStyle(30),
              }}
              alignment={{ horizontal: 'middle' }}
              text={account?.first_name}
            />
            <Text
              {...roles('DaylightCollapsedNavEmail')}
              style={{
                color: theme.color.text.primary,
                paddingTop: 6,
                fontSize: transformStyle(14),
                padding: transformStyle(10),
                paddingHorizontal: transformStyle(30),
              }}
              alignment={{ horizontal: 'middle' }}
              text={account?.email_address}
            />
          </NameContainer>

          <ItemsContainer
            style={{
              paddingTop: transformVerticalStyle(16),
            }}
          >
            {settingsOptions.map(itemConfig => (
              <MenuItem
                key={itemConfig.copy}
                testID={itemConfig.key}
                onPress={() => {
                  history.push(itemConfig.path);
                }}
              >
                <Text
                  style={{
                    paddingHorizontal: transformStyle(24),
                    paddingVertical: transformStyle(16),
                    color: itemConfig.copyColor || theme.color.text.primary,
                    fontSize: transformStyle(18),
                  }}
                  text={itemConfig.copy}
                />
              </MenuItem>
            ))}
            {isDevMode() ? (
              <MenuItem onPress={onHelpPress}>
                <Text
                  style={{
                    paddingHorizontal: transformStyle(24),
                    paddingVertical: transformStyle(16),
                    color: 'green',
                    fontSize: transformStyle(18),
                  }}
                  text={'QA Menu'}
                />
              </MenuItem>
            ) : null}
          </ItemsContainer>
          <FooterCollapsedNav onLogoutPress={onLogoutPress} />
          <View
            {...roles('SettingsCloseButton')}
            style={{
              position: 'absolute',
              top: transformVerticalStyle(top + 15),
              right: transformStyle(15),
            }}
          >
            <CloseIcon
              size={transformStyle(16)}
              color={'#6A717D'}
              onPress={setSettingsScreen}
            />
          </View>
        </ShadowedMenuContainer>
      </Stretcher>
    </MenuPortal>
  );
};
