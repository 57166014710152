import React, { FunctionComponent, useContext } from 'react';

import { MetricsProps } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period/';

import { NavBarStyles } from 'components/Navigation/constants';
import {
  ScreenResponsiveLayoutContext,
  useTransformContentStyle,
} from 'components/ResponsiveLayout';
import { sleepioColors } from 'config/getThemeForProducts/themes/sleepio';

import {
  Container,
  MetricContainer,
  MetricItemTitle,
  MetricItemValue,
  MetricItemView,
  MetricTitle,
} from './styled';

/**
 * Component for Web.
 * Based on the sceen width we differentiate between Mobile and Desktop.
 */
const Metrics: FunctionComponent<MetricsProps> = (props: MetricsProps) => {
  const { heading, items } = props;

  const scaleFn = useTransformContentStyle();

  const { screenWidth } = useContext(ScreenResponsiveLayoutContext);
  const isMobileWeb =
    screenWidth <= NavBarStyles.navBarCollapseMobileWebBreakPointPx;

  return (
    <Container style={{ paddingVertical: '3%' }}>
      <MetricTitle {...heading} style={{ fontSize: scaleFn(36) }} />
      <MetricContainer style={{ marginTop: '3%' }}>
        {items.map((item, index) => (
          <MetricItemView
            key={index}
            style={{
              width: isMobileWeb ? '100%' : '49%',
              paddingVertical: '2.5%',
              paddingLeft: '5%',
            }}
          >
            <MetricItemTitle
              {...item.label}
              style={{
                fontSize: scaleFn(28),
                color: sleepioColors.altDarkBlue,
              }}
            />
            <MetricItemValue
              {...item.value}
              style={{
                color: item.value.color,
                fontSize: scaleFn(40),
                fontWeight: '500',
              }}
            />
          </MetricItemView>
        ))}
      </MetricContainer>
    </Container>
  );
};

export { Metrics };
