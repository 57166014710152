export enum NetworkStates {
  IDLE = 'IDLE',
  FETCHING = 'FETCHING',
  SUCCESS = 'SUCCESS',
}

export enum DomainIDs {
  USER = 'user',
  PLAYER = 'player',
  DOWNLOADER = 'downloader',
  QUESTION_RESPONSE = 'question-response',
  QUESTION_PATH = 'question-path',
  CONTENT_MODAL = 'content-modal',
  NOTIFICATIONS = 'notifications',
  REDIRECTOR = 'redirector',
  DEBUG = 'debug',
  SLEEP_DIARY_MODAL = 'sleep-diary-modal',
}

export enum BundleIds {
  // TODO: double check if SLEEPIO, DAYLIGHT and HELLO_WORLD
  // have correct bundle ids
  SLEEPIO = 'com.bighealth.sleepio.platgen',
  DAYLIGHT = 'com.bighealth.daylight.platgen',
  HELLO_WORLD = 'com.bighealth.helloworld.platgen',
  SLEEPIO_DEV = 'com.bighealth.sleepio.platgen.development',
  SLEEPIO_QA = 'com.bighealth.sleepio.platgen.qa',
  SLEEPIO_STAGE = 'com.bighealth.sleepio.platgen.staging',
  SLEEPIO_PROD = 'com.bighealth.sleepio.platgen.production',
  DAYLIGHT_DEV = 'com.bighealth.daylight.platgen.development',
  DAYLIGHT_QA = 'com.bighealth.daylight.platgen.qa',
  DAYLIGHT_STAGE = 'com.bighealth.daylight.platgen.staging',
  DAYLIGHT_PROD = 'com.bighealth.daylight.platgen.production',
}

export enum AppleStoreIds {
  DAYLIGHT_PROD = '1555718085',
  SLEEPIO_PROD = '1538191784',
}

export enum ApplicationNames {
  HELLO_WORLD = 'Hello World',
  SLEEPIO = 'Sleepio',
  SLEEPIO_DEV = 'SleepioDev',
  SLEEPIO_QA = 'SleepioQA',
  SLEEPIO_STAGE = 'SleepioStage',
  SLEEPIO_PROD = 'SleepioProd',
  DAYLIGHT = 'Daylight',
  DAYLIGHT_DEV = 'DaylightDev',
  DAYLIGHT_QA = 'DaylightQA',
  DAYLIGHT_STAGE = 'DaylightStage',
  DAYLIGHT_PROD = 'DaylightProd',
}

export enum ProductReferences {
  HELLO_WORLD = 'helloworld',
  SLEEPIO = 'sleepio',
  DAYLIGHT = 'daylight',
}

export enum ProgramIds {
  BLOSSOM = 324,
  DAYLIGHT = 269,
  DAYLIGHT_CLINICAL_RESEARCH_PROGRAM = 314,
  DAYLIGHT_PIVOTAL_CLINICAL_RESEARCH_PROGRAM = 336,
}

export enum ButtonTypes {
  LARGE_CTA = 'LARGE_CTA',
  PRIMARY_BUTTON = 'PRIMARY_BUTTON',
  SECONDARY_BUTTON = 'SECONDARY_BUTTON',
  FACEBOOK_BUTTON = 'FACEBOOK_BUTTON',
  GOOGLE_BUTTON = 'GOOGLE_BUTTON',
}

export enum TextTypes {
  DEFAULT = 'DEFAULT',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  HINT = 'HINT',
  SUCCESS = 'SUCCESS',
  CONTENT = 'CONTENT',
}

export enum VisibilityOverride {
  HIDE = 'hidden',
  SHOW = 'shown',
}

export enum AccessibilityRoles {
  HEADER = 'header',
  BUTTON = 'button',
}

export enum Roles {
  HEADING = 'heading',
  BUTTON = 'button',
}

export enum UserAccountType {
  PLATGEN = 'PLATGEN',
  EXTERNAL = 'EXTERNAL',
}
