import { Accessibility } from '@bighealth/types/src';

import { AccessibilityRoles, Roles } from './enums';

const H1: Accessibility = {
  role: Roles.HEADING,
  accessibilityRole: AccessibilityRoles.HEADER,
  level: 1,
};

const H2: Accessibility = {
  role: Roles.HEADING,
  accessibilityRole: AccessibilityRoles.HEADER,
  level: 2,
};

const H3: Accessibility = {
  role: Roles.HEADING,
  accessibilityRole: AccessibilityRoles.HEADER,
  level: 3,
};

const Button: Accessibility = {
  role: Roles.BUTTON,
  accessibilityRole: AccessibilityRoles.BUTTON,
};

export default { H1, H2, H3, Button };
