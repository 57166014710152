import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getQueryClient } from 'components/ProvidersContainer/getQueryClient';
import { useSafeParams } from 'components/Routes/useSafeParams';
import { useHistory } from 'cross-platform/react-router';
import { logoutSuccessHandler } from 'lib/api/helpers/logoutSuccessHandler/logoutSuccessHandler';
import { getRedirectUri } from 'lib/navigation/getRedirectUri';
import { openInAppBrowser } from 'lib/navigation/openInAppBrowser';
import * as reporter from 'lib/reporter';
import { GENERAL_ERROR_MESSAGE } from 'lib/strings';
import { STATE_RESET } from 'state/store/actions';

import { queryClientLogout } from '../queryClientAnalogs/queryClientLogout';

type Callback = () => Promise<void>;

/**
 * Returns a function that logs the user out, clear the queryClient, resets the state,
 * then calls the provided redirect if provided. Redirects to the current product's /login
 * endpoint if no callback is provided.
 * @param onFinishPath The path to redirect to after logout and state reset is done
 */
export const useLazyQueryLogout = (onFinishPath?: string): Callback => {
  const { productReference } = useSafeParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = getQueryClient();
  return useCallback(async () => {
    let idpLogoutUrl;
    try {
      const logoutRes = await queryClientLogout();
      // OIDC users will have an idp_logout_uri
      idpLogoutUrl = logoutRes?.result?.idp_logout_uri;
    } catch (e) {
      reporter.log(`${GENERAL_ERROR_MESSAGE} logging out`, e);
      // NOOP
    } finally {
      queryClient.clear();
      dispatch({ type: STATE_RESET });
      history.push(onFinishPath ?? `/${productReference}/login`);

      // We must call the logout url to clear the OIDC session
      // I.e. logout of Auth0
      if (idpLogoutUrl) {
        const redirectUri =
          onFinishPath ?? getRedirectUri(productReference, 'login');
        const logoutUrl = `${idpLogoutUrl}?returnTo=${redirectUri}`;
        await openInAppBrowser(logoutUrl);
      }
      logoutSuccessHandler();
    }
  }, [dispatch, history, productReference, queryClient, onFinishPath]);
};
