import React, { HTMLProps, ReactElement, ReactNode, useContext } from 'react';
import { AccessibilityProps } from 'react-native';
import styled, { CSSProperties, ThemeContext } from 'styled-components';

import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import { getControlThemes } from 'components/layout/Content/Control/styled';
import {
  Button as ButtonWeb,
  Label,
  Span,
} from 'components/primitives/web-only';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { RoleProps, roles } from 'cross-platform/utils/roleProps';
import {
  ButtonStates,
  getStylesForButtonStates,
} from 'lib/styles/inputStateStyles';

import { GenericInputIcon } from './GenericButtonIcon';
import { GenericInputButtonAlign, GenericInputButtonProps } from './types';

//outline attribute impacts keyboard
const ButtonStatic = styled(ButtonWeb)<ButtonStates & GenericInputButtonProps>`
  appearance: none;
  border: ${props => getStylesForButtonStates('border', props.theme)};
  border-color: ${props =>
    getStylesForButtonStates('borderColor', props.theme)};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0;
  ${getControlThemes}
`;

export const Button: React.FC<HTMLProps<
  HTMLButtonElement & ButtonStates & GenericInputButtonProps
>> = ({ style, ...rest }: { style?: CSSProperties }): ReactElement => {
  const { height, borderRadius, fontSize } = useGetDynamicInputStyles();
  return (
    <ButtonStatic
      {...rest}
      style={{
        ...(style || {}),
        minHeight: height,
        fontSize,
        borderRadius,
      }}
    />
  );
};

export const GenericButtonTextContainer = styled(Span)<{
  align?: GenericInputButtonAlign;
  showBorder?: boolean;
  minHeight?: number;
}>`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const GenericButtonTextStatic = styled(Label)<
  ButtonStates & GenericInputButtonProps
>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
`;

export const GenericButtonText: React.FC<HTMLProps<HTMLDivElement> &
  ButtonStates &
  GenericInputButtonProps &
  AccessibilityProps> = ({
  style,
  ...rest
}: {
  style?: CSSProperties;
} & RoleProps): ReactElement => {
  const {
    fontSize,
    fontWeight,
    paddingLeft,
    paddingRight,
    paddingBottom,
    paddingTop,
    height,
  } = useGetDynamicInputStyles();
  return (
    <GenericButtonTextStatic
      {...rest}
      {...roles.pass(rest, 'text')}
      style={{
        ...(style || {}),
        minHeight: height - paddingTop - paddingBottom,
        fontWeight,
        fontSize,
        paddingLeft,
        paddingRight,
        flex: 1,
        paddingTop,
        paddingBottom,
      }}
    />
  );
};

export const GenericButtonEditText: React.FC<HTMLProps<HTMLDivElement> &
  ButtonStates &
  GenericInputButtonProps> = ({
  style,
  isSelected,
  ...rest
}: {
  style?: CSSProperties;
} & ButtonStates): ReactElement => {
  const theme = useContext(ThemeContext);
  const {
    inputEditFontSize,
    paddingRight,
    height,
  } = useGetDynamicInputStyles();
  return (
    <GenericButtonTextStatic
      {...rest}
      style={{
        ...(style || {}),
        fontSize: inputEditFontSize,
        paddingRight,
        paddingLeft: paddingRight,
        height,
        color: isSelected
          ? 'inherit'
          : getStylesForButtonStates('editColor', theme)({}),
        fontWeight: 'bold',
      }}
    />
  );
};

export const GenericButtonLabelText: React.FC<HTMLProps<HTMLDivElement> &
  ButtonStates &
  GenericInputButtonProps> = ({
  style,
  isSelected,
  quiz,
  ...rest
}: {
  style?: CSSProperties;
} & ButtonStates): ReactElement => {
  const theme = useContext(ThemeContext);
  const { paddingRight, height, labelFontSize } = useGetDynamicInputStyles();
  return (
    <GenericButtonTextStatic
      {...rest}
      style={{
        ...(style || {}),
        fontSize: labelFontSize,
        paddingRight,
        height,
        maxWidth: '50%',
        textAlign: 'right',
        color: isSelected
          ? 'inherit'
          : getStylesForButtonStates('labelColor', theme)({ quiz }),
      }}
    />
  );
};

export const GenericButton = ({
  onClick,
  children,
  isMultiSelect,
  ...rest
}: GenericInputButtonProps &
  AccessibilityProps & { style?: CSSProperties }): ReactElement => {
  const { onFocus, onBlur } = useInputFocusContext();

  const handleBlur = () => {
    onBlur();
  };

  const handleFocus = () => {
    // we don't want to call focus on the button that is
    // a non editing state of the input
    if (!rest.isInput) {
      onFocus();
    }
  };

  return (
    <Button
      {...rest}
      {...roles('', {
        role: rest.role || (isMultiSelect ? 'checkbox' : 'radio'),
        checked: rest.isSelected,
      })}
      onClick={onClick}
      onBlur={handleBlur}
      onFocus={handleFocus}
      label={rest.displayValue}
      tabIndex={0}
      aria-checked={rest.isSelected}
    >
      {typeof children === 'function' ? children() : children}
    </Button>
  );
};

export const GenericInputButton = ({
  isActive,
  onClick,
  children,
  isSelected,
  label,
  quiz,
  isInput,
  highlight,
  id,
  isMultiSelect,
  onSelect,
  ...rest
}: GenericInputButtonProps &
  AccessibilityProps & {
    label?: ReactNode;
    style?: CSSProperties;
  }): ReactElement => {
  const { height, iconSize, inputIconMarginRight } = useGetDynamicInputStyles();

  const textId = 'generic_button_text' + id;
  const labelId = 'generic_button_label' + id;
  const ariaLabelledBy = rest.questionId + ' ' + textId + ' ' + labelId;
  return (
    <GenericButton
      {...rest}
      {...roles.pass(rest, undefined, {
        default: 'GenericInputButton',
      })}
      isActive={isActive}
      isInput={isInput}
      isSelected={isSelected}
      quiz={quiz}
      highlight={highlight}
      id={id?.toString()}
      isMultiSelect={isMultiSelect}
      onClick={onClick}
      aria-labelledby={ariaLabelledBy}
    >
      {typeof children === 'function'
        ? children // Pass through - consumer has to do the work of formatting etc
        : (
            fnProps = {}
          ): ReactElement => ( // We do all the formatting the work for the consumer
            <GenericButtonTextContainer>
              <GenericButtonText
                isActive={isActive}
                isSelected={isSelected}
                isHovering={fnProps.isPressing}
                quiz={quiz}
                highlight={highlight}
                {...roles.pass(rest, 'ButtonText')}
                htmlFor={'option_' + id}
                id={textId}
                //
                aria-hidden={true}
              >
                {children}
              </GenericButtonText>
              {label ? (
                <GenericButtonLabelText
                  isActive={isActive}
                  isSelected={isSelected}
                  isHovering={fnProps.isPressing}
                  quiz={quiz}
                  highlight={highlight}
                  {...roles.pass(rest, 'LabelText')}
                  id={labelId}
                  aria-label={'(' + label + ')'}
                  //
                  aria-hidden={true}
                >
                  {label}
                </GenericButtonLabelText>
              ) : null}
              <GenericInputIcon
                quiz={quiz}
                isActive={isActive}
                style={{
                  marginTop: 0.5 * (height - iconSize),
                  marginRight: inputIconMarginRight,
                }}
                size={iconSize}
              />
            </GenericButtonTextContainer>
          )}
    </GenericButton>
  );
};
