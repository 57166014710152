import { useEffect } from 'react';
import { flatten } from 'ramda';

import { get_user_session_screen_data } from '@bighealth/api/SessionProgress/v1';

import {
  nonSceneSetAssetsStatus,
  useLazyQueryDownloadNonSceneSetAssets,
  useQueryAllForSessionScreen,
} from 'lib/api/reactQueryHelpers';
import { isResultMobileWeb } from 'lib/utils/guards/isResultMobileWeb';

type useSessionsDataQueryReturn = {
  isLoading: boolean;
  data: get_user_session_screen_data.Response | undefined;
};

export const getAllChildNodes = (
  data: get_user_session_screen_data.ResultComponentData,
  acc: get_user_session_screen_data.SessionScreenComponent[]
): get_user_session_screen_data.SessionScreenComponent[] => {
  data.childNodes.forEach(childNode => {
    if (childNode.component === 'Group') {
      getAllChildNodes(childNode, acc);
    }
    acc.push(childNode);
  });

  return acc;
};

export const useSessionsDataQuery = (): useSessionsDataQueryReturn => {
  const { data, isLoading } = useQueryAllForSessionScreen();
  const { status, downloadAssets } = useLazyQueryDownloadNonSceneSetAssets();

  useEffect(() => {
    if (
      status === nonSceneSetAssetsStatus.IDLE &&
      typeof data?.result !== 'undefined'
    ) {
      if (isResultMobileWeb(data?.result)) {
        downloadAssets(getAllChildNodes(data.result.main, []));
      } else {
        const assetsToDownload = [];
        if (typeof data.result.modal !== 'undefined') {
          assetsToDownload.push(
            data.result.modal.header.childNodes,
            data.result.modal.main.column_1.childNodes,
            data.result.modal.main.column_2.childNodes
          );
        }
        if (typeof data.result.distribution_buttons !== 'undefined') {
          assetsToDownload.push(data.result.distribution_buttons.childNodes);
        }

        downloadAssets(flatten(assetsToDownload));
      }
    }
  }, [data, downloadAssets, status]);

  if (
    typeof data?.result !== 'undefined' &&
    // requires asset fetching when there is a modal or result is mobile web
    (isResultMobileWeb(data?.result) ||
      typeof data.result.modal !== 'undefined' ||
      typeof data.result.distribution_buttons !== 'undefined')
  ) {
    switch (status) {
      case nonSceneSetAssetsStatus.FAILURE:
        return {
          isLoading: false,
          data: undefined,
        };
      case nonSceneSetAssetsStatus.LOADING:
      case nonSceneSetAssetsStatus.IDLE:
        return {
          isLoading: true,
          data: undefined,
        };
    }
  }

  return {
    data,
    isLoading,
  };
};
