import * as React from 'react';
import { ReactElement, useContext } from 'react';
import { StyleSheet } from 'react-native';
import styled, { ThemeContext } from 'styled-components';

import { printableProps } from 'components/layout/lib/printableProps';
import { Text } from 'components/Text';
import { accessibility } from 'cross-platform/utils/accessibilityProps';
import { roles } from 'cross-platform/utils/roleProps';
import { getTestIdValue } from 'cross-platform/utils/roleProps/getTestId';

import { ButtonProps } from './types';
import { useButtonInteractionStates } from './useButtonInteractionStates';
import { useDynamicButtonDimensions } from './useDynamicButtonDimensions';

const ButtonStatic = styled.button`
  align-items: center;
  appearance: none;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
`;

export const UniversalButton = (props: ButtonProps): ReactElement => {
  const {
    isDisabled = false,
    text,
    onPress,
    colorScheme,
    style,
    ...rest
  } = props;
  const theme = useContext(ThemeContext);
  const interactionStates = useButtonInteractionStates({
    ...props,
    colorScheme,
  });
  const dimensions = useDynamicButtonDimensions({ ...props, theme });

  const {
    style: { backgroundColor, color, borderColor },
    webEvents: {
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onMouseDown,
    },
  } = interactionStates;

  const { fontSize, fontWeight, lineHeight, ...viewDimensions } = dimensions;
  const flatStyles = StyleSheet.flatten(style || {});

  return (
    <ButtonStatic
      {...rest}
      {...roles(
        getTestIdValue(rest) || `Button_${printableProps({ text, isDisabled })}`
      )}
      {...accessibility(
        rest?.accessibility || { role: 'button', accessibilityRole: 'button' }
      )}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={isDisabled}
      onClick={onPress}
      style={{
        ...(flatStyles as Record<string, unknown>), // Work around TypeScript bug https://github.com/Microsoft/TypeScript/issues/10727
        ...StyleSheet.flatten(viewDimensions),
        backgroundColor,
        borderColor,
        borderStyle: 'solid',
      }}
      aria-label={text?.toString()}
    >
      <Text
        style={{ color, fontSize, fontWeight, lineHeight, textAlign: 'center' }}
        aria-hidden={true}
      >
        {text}
      </Text>
    </ButtonStatic>
  );
};
