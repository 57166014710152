import React from 'react';

import { roles } from 'cross-platform/utils/roleProps';

import { CardPresentationProps } from '../CardPresentation';
import {
  IconByStatus,
  IconOverlay,
  LockIcon,
  LockText,
  TitleText,
  Touchable,
} from '../CardPresentation/styled';

import {
  Image,
  LabelText,
  OptionalContantWrapper,
  OptionalContent,
  Wrapper,
} from './styled';

type OptionalCardProps = Omit<
  CardPresentationProps,
  | 'heading'
  | 'status_text'
  | 'variant'
  | 'isFirstChild'
  | 'isLastChild'
  | 'isLarge'
>;

const OptionalCard = ({
  status,
  scaleValue,
  onPress,
  play_action,
  title,
  duration,
  session_thumb_image_asset,
  lock_text,
}: OptionalCardProps): React.ReactElement => {
  return (
    <Wrapper scaleValue={scaleValue} status={status} style={{}}>
      <OptionalContantWrapper {...roles('OptionalCard')}>
        <Image
          {...roles('OptionalContentImage')}
          src={session_thumb_image_asset.storage_url}
          assetSize={{
            width: session_thumb_image_asset.width,
            height: session_thumb_image_asset.height,
          }}
          alt={session_thumb_image_asset.alt_text}
        />
        <OptionalContent accessible={true}>
          <TitleText
            style={{ fontWeight: 500, fontSize: 20, marginBottom: 8 }}
            scaleValue={scaleValue}
            {...title}
            {...roles('OptionalCardTitle')}
          />
          <LabelText scaleValue={scaleValue} {...duration}></LabelText>
          <Touchable
            onPress={onPress}
            disabled={!play_action?.type}
            accessible={true}
            accessibilityLabel={'Play' + title?.text}
            accessibilityRole={'button'}
            {...roles('OptionalCardButton')}
            style={{
              position: 'absolute',
              bottom: 16,
              right: 24,
            }}
          >
            <IconByStatus
              status={status}
              scaleValue={scaleValue}
              iconSize={33}
            />
          </Touchable>
        </OptionalContent>
        {status === 'LOCKED' ? (
          <IconOverlay locked {...roles('OptionalCardLock')}>
            <LockIcon />
            <LockText {...lock_text} {...roles('OptionalLockText')} />
          </IconOverlay>
        ) : null}
      </OptionalContantWrapper>
    </Wrapper>
  );
};

export { OptionalCard };
