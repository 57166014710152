import React, { ReactElement, useContext } from 'react';
import { Linking, ScrollView, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import { ThemeContext } from 'styled-components/native';

import { useProductStreamVersion } from 'components/Footer/hooks/useProductStreamVersion';
import { ThrobberBehavior } from 'components/Routes/utils/isFetchingWithThrobberPredicateFactory/checkThrobberBehavior';
import { UKCAMark } from 'components/Screens/ContentScreens/components';
import { InstructionsIcon, ManufacturerIcon } from 'daylight/components/icons';
import { useQueryProgram } from 'lib/api/reactQueryHelpers';
import { useTransformContentStyle } from 'lib/hooks/useTransformContentStyle';

import { SettingsScreen } from '../SettingsScreen';
import {
  SettingsContentLinkText,
  SettingsContentText,
} from '../SettingsScreen.styled';

import { getIFUContent } from './aboutDaylightContentLinks';

export const AboutDaylightView = (): ReactElement => {
  const { data } = useQueryProgram();
  const transformStyle = useTransformContentStyle();
  const theme = useContext(ThemeContext);
  const { bottom } = useSafeArea();
  const productStreamVersion = useProductStreamVersion({
    meta: { throbberBehavior: ThrobberBehavior.ALWAYS_HIDDEN },
  });
  const aboutDaylightContent = getIFUContent(data?.result.id);
  return (
    <SettingsScreen title={'About Daylight'}>
      <ScrollView style={{ height: '100%' }}>
        <View
          style={{
            paddingLeft: transformStyle(24),
            paddingTop: transformStyle(30),
            bottom,
          }}
        >
          {aboutDaylightContent.map((content, index) => {
            return (
              <SettingsContentLinkText
                testID={content.testID}
                key={index}
                onPress={() => Linking.openURL(content.link)}
                fontSize={transformStyle(18)}
                lineHeight={transformStyle(24.3)}
                marginTop={transformStyle(24)}
              >
                {content.text}
              </SettingsContentLinkText>
            );
          })}
          <SettingsContentText
            fontSize={transformStyle(18)}
            marginBottom={transformStyle(10)}
            lineHeight={transformStyle(24.3)}
            style={{
              paddingBottom: transformStyle(20),
              paddingTop: transformStyle(20),
            }}
          >
            Daylight is created and maintained by Big Health Inc.
          </SettingsContentText>
          <ManufacturerIcon />
          <SettingsContentText
            fontSize={transformStyle(18)}
            marginBottom={transformStyle(10)}
            lineHeight={transformStyle(24.3)}
            style={{
              paddingBottom: transformStyle(20),
              paddingTop: transformStyle(20),
            }}
          >
            Big Health Inc.{'\n'}
            461 Bush St. #200{'\n'}
            San Francisco, CA 94108{'\n'}
            United States
          </SettingsContentText>
          <SettingsContentText
            fontSize={transformStyle(18)}
            marginBottom={transformStyle(10)}
            lineHeight={transformStyle(24.3)}
            style={{ textDecorationLine: 'underline' }}
          >
            Importer / UK Responsible Person:
          </SettingsContentText>
          <SettingsContentText
            fontSize={transformStyle(18)}
            marginBottom={transformStyle(10)}
            lineHeight={transformStyle(24.3)}
            style={{ paddingBottom: transformStyle(10) }}
          >
            Big Health Ltd.{'\n'}
            Lynton House{'\n'}
            7-12 Tavistock Square{'\n'}
            London{'\n'}
            WC1H 9LT{'\n'}
            United Kingdom
          </SettingsContentText>
          <View
            style={{
              marginLeft: transformStyle(-10),
              paddingBottom: transformStyle(10),
            }}
          >
            <UKCAMark color={theme.color.black} size={transformStyle(60)} />
          </View>
          <InstructionsIcon size={transformStyle(45)} />
          <SettingsContentText
            fontSize={transformStyle(18)}
            marginBottom={transformStyle(10)}
            lineHeight={transformStyle(24.3)}
            style={{
              paddingTop: transformStyle(20),
            }}
          >
            Daylight is a medical device.
          </SettingsContentText>
          <SettingsContentText
            fontSize={transformStyle(18)}
            marginBottom={transformStyle(10)}
            lineHeight={transformStyle(24.3)}
            style={{
              paddingTop: transformStyle(20),
              paddingBottom: transformStyle(20),
            }}
          >
            {productStreamVersion}
          </SettingsContentText>
        </View>
      </ScrollView>
    </SettingsScreen>
  );
};
