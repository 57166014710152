import React, {
  ReactElement,
  RefAttributes,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  StyleProp,
  TextInput as RNTextInput,
  TextInputProps,
  TextStyle,
} from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';

import { useInputFocusContext } from 'components/forms/ResponseOptions/providers/InputFocusProvider';
import { TextInput } from 'components/primitives/cross-platform';
import { useGetDynamicInputStyles } from 'components/ResponsiveLayout';
import { roles } from 'cross-platform/utils/roleProps';
import {
  getStylesForInputStates,
  InputStates,
} from 'lib/styles/inputStateStyles';

import { usePlayerControlsDismiss } from '../GenericInput/hooks';

import { parsePhone, phoneMask } from './helpers';
import { RawPhoneNumberInputProps } from './types';

type InputFullProps = InputStates & {
  onBlur?: () => void;
} & RefAttributes<RNTextInput> & {
    style?: StyleProp<TextStyle>;
  } & TextInputProps;

// NOTE: This is the existing text input styling from GenericInput
// TODO: Update theme to support new and product specific styling.
const InputPhone = styled(TextInput)<InputFullProps>`
  background-color: ${getStylesForInputStates('backgroundColor')};
  border: ${getStylesForInputStates('border')};
  color: ${getStylesForInputStates('color')};
  display: flex;
  width: 100%;
  font-style: ${getStylesForInputStates('fontStyle')};
`;

export const PhoneNumber = ({
  onPress,
  onValueChange,
  value,
  defaultCountry,
}: RawPhoneNumberInputProps): ReactElement => {
  // Setup initial values before putting them into state
  const { phoneVal: initialPhone, e164Val: initialE164 } = parsePhone(value);

  const [inputValue, setInputValue] = useState(initialPhone);
  const [e164Value, setE164Value] = useState(initialE164);
  const { onFocus, onBlur } = useInputFocusContext();
  const inputNode = useRef(null);
  const playerControlsDismiss = usePlayerControlsDismiss(inputNode);

  const theme = useContext(ThemeContext);
  const {
    height,
    borderRadius,
    paddingLeft,
    paddingRight,
    fontSize,
    fontStyle,
  } = useGetDynamicInputStyles(theme);

  const handleValueChange = (value: string): void => {
    // Remove anything that is not a digit
    const cleanValue = value.replace(/\D/g, '');

    const { phoneVal, e164Val } = parsePhone(cleanValue);

    // We want the formatted value displayed
    setInputValue(phoneVal);
    setE164Value(e164Val);
  };

  const handleFocus = (): void => {
    onFocus();
    onPress?.();
    playerControlsDismiss();
  };
  const handleBlur = (): void => {
    onBlur();
    // Put the e164 value in Formik state and run the validation schema.
    // TODO: Use phone library validation instead of the schema.
    // WHEN: https://bighealth.atlassian.net/browse/TP-679
    onValueChange(e164Value);
  };

  return (
    <InputPhone
      {...roles('GenericInput-phoneNumber')}
      placeholder={phoneMask(defaultCountry)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChangeText={handleValueChange}
      value={inputValue}
      ref={inputNode}
      style={{
        height,
        paddingLeft,
        paddingRight,
        paddingBottom: 0,
        paddingTop: 0,
        fontSize,
        borderRadius,
        fontStyle,
      }}
      keyboardType="phone-pad"
    />
  );
};
