import React from 'react';

import { FlexBox, FlexBoxProps } from '../../FlexBox';

type Props = Pick<
  FlexBoxProps,
  | 'flexGrow'
  | 'flexShrink'
  | 'width'
  | 'style'
  | 'flexWrap'
  | 'children'
  | 'flexBasis'
>;
const Col = ({ ...props }: Props): React.ReactElement => <FlexBox {...props} />;
Col.displayName = 'Col';

export { Col };
