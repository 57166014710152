import styled from 'styled-components/native';

import {
  Category,
  DataPoint,
} from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

import { Text } from 'components/sceneset-components/Text';

import { getChartColumnHeight } from './utils/getChartColumnHeight';

type BarChartColumnProps = DataPoint & { upperYValue: number };

export const Container = styled.View`
  background-color: white;
`;

// Guides
export const BarChartGuideContainer = styled.View`
  height: 76%;
  width: 100%;
  position: absolute;
  justify-content: space-between;
`;

export const BarChartGuide = styled.View`
  width: 100%;
`;

export const BarChartGuideLine = styled.View`
  border-bottom-color: rgba(26, 128, 162, 0.29);
  border-bottom-width: 1px;
  width: 100%;
`;

export const BarChartGuideLabel = styled<typeof Text>(Text)`
  color: #1a80a2;
`;

// Columns

export const BarChartColumnCont = styled.View`
  height: 76%;
  width: 82%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const BarChartColumn = styled.View`
  align-items: center;
  justify-content: flex-end;
  width: 12.5%;
  height: 100%;
`;

export const BarChartColumnFillContainer = styled.View`
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

export const BarChartColumnFill = styled.View<BarChartColumnProps>`
  height: ${props => getChartColumnHeight(props.y_value, props.upperYValue)};
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: ${props => props.color};
  opacity: ${props => props.opacity || '1.0'};
`;

// Label
export const BarChartLabelCont = styled.View`
  flex-direction: row;
  width: 82%
  height: 20%;
  align-items: center;
  justify-content: space-between;
`;

export const BarChartLabelView = styled.View<Category['label']>`
  border-radius: 8px;
  background-color: ${props => props.background_color};
  width: 12.5%;
  height: 40%;
  align-items: center;
  justify-content: center;
`;

export const BarChartLabelText = styled<typeof Text>(Text)`
  font-weight: 700;
  text-align: center;
  color: #ffffff;
`;
