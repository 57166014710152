import React from 'react';

import { StyleObject } from '@bighealth/types/src/scene-components/client';

import { roles } from 'cross-platform/utils/roleProps';
import { useSessionsStyles } from 'sleepio/components/screens/SessionsScreen/hooks/useSessionsStyles';

import { CardPresentationProps } from '../CardPresentation';
import {
  ContentDetails,
  ContentImage,
  DescriptionText,
  FirstCol,
  Grid,
  IconByStatus,
  IconOverlay,
  Image,
  ImageWrapper,
  LabelText,
  LabelView,
  LockIcon,
  LockText,
  Row,
  SecondCol,
  TitleText,
  Touchable,
  Wrapper,
} from '../CardPresentation/styled';

type SessionCardProps = Omit<
  CardPresentationProps,
  | 'heading'
  | 'status_text'
  | 'variant'
  | 'isFirstChild'
  | 'isLastChild'
  | 'isLarge'
>;

const SessionCard = ({
  status,
  play_action,
  onPress,
  scaleValue,
  style,
  title,
  description,
  duration,
  session_thumb_image_asset,
  lock_text,
}: SessionCardProps): React.ReactElement => {
  const { cardStyles } = useSessionsStyles();

  const { contentPadding } = cardStyles;

  return (
    <Wrapper scaleValue={scaleValue} status={status} style={style}>
      <ImageWrapper {...roles('ImageContent')} style={cardStyles.imageWrapper}>
        <Image
          {...roles('Image')}
          src={session_thumb_image_asset.storage_url}
          assetSize={{
            width: session_thumb_image_asset.width,
            height: session_thumb_image_asset.height,
          }}
          alt={session_thumb_image_asset.alt_text}
        />
        {status === 'LOCKED' ? (
          <IconOverlay locked>
            <LockIcon />
            <LockText {...lock_text} {...roles('LockText')} />
          </IconOverlay>
        ) : null}
        <ContentImage {...roles('Content')}>
          <LabelView {...roles('LabelView')}>
            <LabelText
              style={cardStyles.contentStyles.labelTextStyles as StyleObject}
              scaleValue={scaleValue}
              {...duration}
            ></LabelText>
          </LabelView>
        </ContentImage>
      </ImageWrapper>
      <ContentDetails style={contentPadding} {...roles('Content')}>
        <Grid>
          <Row>
            <FirstCol>
              <TitleText
                style={cardStyles.contentStyles.titleStyles as StyleObject}
                scaleValue={scaleValue}
                {...title}
                {...roles('CardTitle')}
              />
              <DescriptionText
                style={
                  cardStyles.contentStyles.descriptionStyles as StyleObject
                }
                scaleValue={scaleValue}
                {...description}
                {...roles('CardDescription')}
              />
            </FirstCol>
            <SecondCol {...roles('icon-status-' + status)}>
              <Touchable
                onPress={onPress}
                disabled={!play_action?.type}
                accessible={true}
                accessibilityLabel={'Play Session' + title?.text}
                accessibilityRole={'button'}
              >
                <IconByStatus
                  status={status}
                  scaleValue={scaleValue}
                  iconSize={cardStyles.contentStyles.newIconSize}
                />
              </Touchable>
            </SecondCol>
          </Row>
        </Grid>
      </ContentDetails>
    </Wrapper>
  );
};

export { SessionCard };
