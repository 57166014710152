import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { Col, Row as RowRaw } from 'components/layout/Grid';
import { Text } from 'components/sceneset-components/Text';
import { PadLockSvg } from 'components/Screens/ContentScreens';
// import { createThemeByAttr } from 'config/getThemeForProducts/utils/createTheme/ComponentTheme';
import CrossPlatformImage from 'cross-platform/Image';
import {
  // CardAttrs,
  // CardTheme,
  // cardThemeCollection,
  sessionsValues,
} from 'sleepio/components/screens/SessionsScreen/hooks/constants';
import { ScaleValueProps } from 'sleepio/components/screens/SessionsScreen/utils/scaler';

import { ICONS_BY_STATUS } from '../../constants/ICONS_BY_STATUS';
import { StatusProps } from '../../utils/byStatus';

import { CardPresentationProps } from '.';

export { Grid } from 'components/layout/Grid';

const DOMAIN = 'ExtendedCard';

export const Touchable = styled.TouchableOpacity``;
Touchable.displayName = `${DOMAIN}.Touchable`;

export const FirstCol = styled(Col)`
  width: 60%;
  overflow: hidden;
  align-self: center;
`;
FirstCol.displayName = `${DOMAIN}.FirstCol`;

export const SecondCol = styled(Col)`
  flex-basis: 0;
  align-content: flex-end;
  align-self: center;
`;
SecondCol.displayName = `${DOMAIN}.SecondCol`;

export const Row = styled(RowRaw)`
  align-content: center;
`;
Row.displayName = `${DOMAIN}.Row`;

type WrapperProps = ScaleValueProps & StatusProps & StyleProp<ViewStyle>;

// const thByAttr = createThemeByAttr<CardAttrs, CardTheme>(cardThemeCollection);
// export const Wrapper = styled.View<WrapperProps>`
//   background-color: #ffffff;
//   width: ${thByAttr(th => th.width)}px;
//   height: ${sessionsValues.card.image.height +
//     sessionsValues.card.details.height}px;
//   margin-left: 12px;
//   margin-right: 12px;
//   border-radius: ${sessionsValues.card.large.borderRadius};
// `;
export const Wrapper = styled.View<WrapperProps>`
  background-color: #ffffff;
  width: 100px;
  height: ${sessionsValues.card.image.height +
    sessionsValues.card.details.height}px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: ${sessionsValues.card.large.borderRadius}px;
`;
Wrapper.displayName = `${DOMAIN}.Wrapper`;

const ICON_BASE_WIDTH = 42;
const ICON_BASE_HEIGHT = 43;
const ICON_VIEW_BOX = `0 0 ${ICON_BASE_WIDTH} ${ICON_BASE_HEIGHT}`;
export const IconByStatus = ({
  status,
  iconSize,
}: Pick<CardPresentationProps, 'status'> &
  ScaleValueProps & { iconSize: number }): React.ReactElement => {
  const IconComponent = ICONS_BY_STATUS[status];
  if (typeof IconComponent === 'undefined') {
    throw Error(
      `No icon for ${status} in ${Object.keys(ICONS_BY_STATUS).join(', ')}`
    );
  }
  return (
    <IconComponent width={iconSize} height={iconSize} viewBox={ICON_VIEW_BOX} />
  );
};
IconByStatus.displayName = `${DOMAIN}.IconByStatus`;

export const DescriptionText = styled(Text).attrs({
  numberOfLines: 3,
})<ScaleValueProps>`
  color: #141a52;
  font-weight: 400;
  margin-bottom: 4px;
`;
DescriptionText.displayName = `${DOMAIN}.DescriptionText`;

export const TitleText = styled(Text).attrs({
  numberOfLines: 2,
})<ScaleValueProps>`
  color: #141a52;
  font-weight: 500;
  margin-bottom: 4px;
`;
TitleText.displayName = `${DOMAIN}.TitleText`;

export const LabelText = styled(Text).attrs({
  numberOfLines: 2,
})<ScaleValueProps>`
  color: #ffffff;
  font-weight: 500;
`;
LabelText.displayName = `${DOMAIN}.LabelText`;

export const LabelView = styled.View`
  background-color: rgba(0, 51, 102, 0.6);
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 6px;
  align-self: flex-end;
`;
LabelView.displayName = `${DOMAIN}.LabelView`;

export const ImageWrapper = styled.View`
  height: 170px;
  overflow: hidden;
`;
ImageWrapper.displayName = `${DOMAIN}.ImageWrapper`;

export const ContentImage = styled.View`
  height: 170px;
  align-items: flex-end;
  flex-direction: row-reverse;
  padding: 16px;
`;
ContentImage.displayName = `${DOMAIN}.ContentImage`;

export const ContentDetails = styled.View`
  height: 105px;
`;
ContentDetails.displayName = `${DOMAIN}.ContentDetails`;

export const Image = styled(CrossPlatformImage)<{
  src: string;
  assetSize: {
    width: number;
    height: number;
  };
}>`
  width: ${sessionsValues.card.width}px;
  height: ${({ assetSize: { width, height } }) =>
    (sessionsValues.card.width / width) * height}px;
  position: absolute;
  top: -12px; /* From figma */
`;
Image.displayName = `${DOMAIN}.Image`;

export const IconOverlay = styled.View<{ locked?: boolean }>`
  background-color: ${({ locked }) =>
    locked ? 'rgba(22, 50, 88, 0.6)' : 'transparent'};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
`;
IconOverlay.displayName = `${DOMAIN}.IconOverlay`;

export const LockIcon = styled(PadLockSvg).attrs({ fill: 'white' })`
  margin-bottom: 4px;
`;
LockIcon.displayName = `${DOMAIN}.LockIcon`;

export const LockText = styled(Text)`
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
`;

LockText.displayName = `${DOMAIN}.LockText`;
LockText.defaultProps = {
  text: '\u00A0' /* nbsp */,
};
