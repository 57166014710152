import React, { ReactElement } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import {
  ScalingMode,
  ViewStylesObject,
} from '@bighealth/types/src/scene-components/client';

import { AccessibleWrapper } from 'components/AccessibleWrapper';
import { useTransformStylesToContext } from 'components/ResponsiveLayout';
import CrossPlatformImage from 'cross-platform/Image';
import { getLocalURIForRemoteURL } from 'state/downloader/selectors';

export type ImageProps = {
  src: string;
  style?: ViewStylesObject;
  scaling?: ScalingMode;
  altText?: string;
};

export const Image = ({
  src,
  style,
  scaling,
  altText,
}: ImageProps): ReactElement => {
  // WARNING - this ONLY loads PRE-CACHED assets
  const transformStylesToContext = useTransformStylesToContext();
  const localURL = useSelector(getLocalURIForRemoteURL(src));
  const scaledStyles = transformStylesToContext(style || {}, scaling);
  const { width, height, ...remainingStyles } = scaledStyles || {};
  return (
    //The styles scaledStyles were added to the View component instead of the CrossPlatformImage, to get better accessibility experience.
    <View pointerEvents={'box-none'} style={remainingStyles}>
      <AccessibleWrapper roleKind="image" accessibilityLabel={altText}>
        <CrossPlatformImage
          src={localURL}
          style={{
            width: width ? width : undefined,
            height: height ? height : undefined,
          }}
          alt={altText}
        />
      </AccessibleWrapper>
    </View>
  );
};
