import React, { useState } from 'react';
import { ReactElement } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { SceneAction } from '@bighealth/types/src/scene-components/client';

import { useHistory, useParams } from 'cross-platform/react-router';
import { roles } from 'cross-platform/utils/roleProps';
import { CtaCirclePlayIcon } from 'daylight/components/icons';
import { useGetSvgDimensions } from 'daylight/hooks';
import { queryClientDaylightCheckin } from 'lib/api/reactQueryHelpers';
import getPathForParams from 'lib/player/getPathForParams';
import useActionHandler from 'lib/player/useActionHandler';
import * as reporter from 'lib/reporter';
import { GENERAL_ERROR_MESSAGE } from 'lib/strings';

export type PlayButtonProps = {
  action?: SceneAction;
  testID?: string;
  style: {
    width: number;
    height: number;
    backgroundColor: string;
    iconColor: string;
  };

  onPress?: () => void;
};

// TODO: return the button to navigate the Daylight content:
// - In case of custom action (Daylight/Start_Checkin action), call the custom endpoint
// - In case of Platform action (Jump to SceneSet, GO_TO actions), use the UniversalButton (via the ActionButton)
export const PlayButton = (props: PlayButtonProps): ReactElement => {
  const { productReference } = useParams<{ productReference: string }>();
  const actionHandler = useActionHandler(props.action);
  const history = useHistory();
  const [playButtonDisabled, setPlayButtonDisabled] = useState(false);

  const handlePress = async (): Promise<void> => {
    // TODO: consider to use Platform code
    // WHEN a Platform package is created.
    if (playButtonDisabled) {
      return;
    } else {
      setPlayButtonDisabled(true);
    }
    if (props.action?.type === 'daylight/START-CHECKIN') {
      try {
        const checkinSS = await queryClientDaylightCheckin({
          product_id: props.action?.payload.productId,
          program_id: props.action?.payload.programId,
          scene_set_graph_id: props.action?.payload.sceneSetGraphId,
          scene_set_id: props.action?.payload.destinationSceneSetId,
        });
        if (checkinSS) {
          const path = getPathForParams({
            productReference: productReference,
            sceneSetGraphId: props.action?.payload.sceneSetGraphId,
            sceneSetId: props.action?.payload.destinationSceneSetId,
            sceneId: 0,
          });
          history.push(path);
        } else {
          history.push(`/${productReference}/home`);
        }
      } catch (e) {
        reporter.log(`${GENERAL_ERROR_MESSAGE} logging out`, e as Error);
      }
    } else if (typeof actionHandler === 'function') {
      await actionHandler();
    }
    setPlayButtonDisabled(false);
  };

  const dimensionProps = useGetSvgDimensions({
    width: props.style.width,
    height: props.style.height,
  });

  return (
    <View>
      <TouchableOpacity {...roles('DaylightPlayButton')} onPress={handlePress}>
        <CtaCirclePlayIcon
          {...dimensionProps}
          circleColor={props.style.backgroundColor}
          triangleColor={props.style.iconColor}
        />
      </TouchableOpacity>
    </View>
  );
};
