import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { find, propEq } from 'ramda';

import { APIRequestBody } from '@bighealth/api';
import { SleepDiaryWeekTypes } from '@bighealth/types/src/scene-components/sleep-diary';
import { Diary } from '@bighealth/types/src/services/SleepDiaryPayloads/get_sleep_diary_component_data_for_time_period';

import { PopupModal } from 'components/PopupModal';
import { useGetDynamicContentStyles } from 'components/ResponsiveLayout';
import { SleepDiaryForm } from 'components/SleepDiaryForm';
import { roles } from 'cross-platform/utils/roleProps';

import { Calendar } from '../calendar';
import { Chart } from '../chart';
import { FitbitSetup } from '../FitbitSetup';
import { Metrics } from '../metrics';

import { shouldContinueButtonExtrapolate } from './utils/shouldContinueButtonExtrapolate';
import { useTryOpenDate } from './utils/useTryOpenDate';
import { CalendarChartContainer } from './CalendarChartContainer';
import { Header } from './Header';
import { BottomPadding, Container } from './styled';

export type PresentationProps = SleepDiaryWeekTypes['PreNetworkCallConfiguration'] &
  SleepDiaryWeekTypes['NetworkResponse'] & {
    pageForwardPress: () => void;
    pageBackwardPress: () => void;
    diaryDateForModal: string | undefined;
    onPressCalendarTile: (diary_date: undefined | string) => void;
    onCloseDiaryModal: (diary_date: undefined | string) => void;
    onDiarySubmit: Dispatch<SetStateAction<number | undefined>>;
    setIsLoading: (loading: boolean) => void;
  };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const findDiaryByDate = (diary_days: Diary[], diary_date: string) => {
  return find(propEq('date', diary_date), diary_days);
};

/**
 * The presentational layer of the SleepDiaryWeek component. See the type
 * definitions for explanations of the various props.
 * @param props
 * @constructor
 */
const Presentation: FunctionComponent<PresentationProps> = (
  props: PresentationProps
) => {
  useTryOpenDate(props.diaries, props.onPressCalendarTile);

  // diaryToPresent is the Diary object from the list of props.diaries.days that is to be presented.
  // It contains the click_actions property that directs the SleepDiary Form to which endpoint to
  // use to populate the form options

  const diaryToPresent =
    typeof props.diaryDateForModal !== 'undefined'
      ? findDiaryByDate(props.diaries.days, props.diaryDateForModal)
      : undefined;

  const styles = useGetDynamicContentStyles();

  const continueButtonShouldExtrapolate = shouldContinueButtonExtrapolate({
    diaryDays: props.diaries.days,
    minCompleteDiaryCount:
      props.minCountOfCompleteDiariesForContinueButtonToExtrapolate,
    maxCompleteDiaryCount:
      props.maxCountOfCompleteDiariesForContinueButtonToExtrapolate,
  });
  return (
    <Container {...roles('sleepDiaryWeekly')}>
      {typeof diaryToPresent !== 'undefined' && diaryToPresent.click_action ? (
        <PopupModal
          visible={true}
          onClose={() => props.onCloseDiaryModal(undefined)}
        >
          <SleepDiaryForm
            renderRequest={
              diaryToPresent.click_action.payload as APIRequestBody
            }
            onSuccessfulSubmit={props.onDiarySubmit}
            onClose={() => props.onCloseDiaryModal(undefined)}
          />
        </PopupModal>
      ) : null}

      <Header {...props} />
      {props.showFitbitSetup === true ? <FitbitSetup /> : null}
      <CalendarChartContainer>
        <Calendar
          {...props.diaries}
          title={props.title}
          titleAlignment={props.titleAlignment}
          subtitle={props.subtitle}
          subtitleAlignment={props.subtitleAlignment}
          pageForwardPress={props.pageForwardPress}
          pageBackwardPress={props.pageBackwardPress}
          onPressCalendarTile={props.onPressCalendarTile}
          shouldContinueButtonExtrapolate={continueButtonShouldExtrapolate}
          shouldShowContinueButton={props.shouldShowContinueButton}
          isContinueButtonEnabled={props.isContinueButtonEnabled}
          continueButtonText={props.continueButtonText}
          setIsLoading={props.setIsLoading}
        />
        {props.meta?.chart ? <Chart {...props.meta.chart} /> : null}
      </CalendarChartContainer>

      {props.meta?.metrics ? <Metrics {...props.meta.metrics} /> : null}

      <BottomPadding style={{ height: styles.sleepDiaryWeekPaddingBottom }} />
    </Container>
  );
};

export { Presentation };
