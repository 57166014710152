import { NotificationGroupConfigId } from 'config/localNotifications';

export type UseRefreshNotificationsArgs = {
  osNotificationPermissionRequestCount: number;
  notificationGroupConfigId: NotificationGroupConfigId;
  sessionNotificationGroupConfigId?: NotificationGroupConfigId;
  programId?: number;
};

export type UseRefreshNotificationsFunc = (
  args: UseRefreshNotificationsArgs
) => { refreshInProgress: boolean };

export type HaveAnyScheduledNotificationsBeenSentFunc = (
  notificationGroupConfigId: NotificationGroupConfigId
) => Promise<boolean | null>;

export type NotificationAlertHandler = () => void;

export type OpenSettingsScreenFunc = () => void;

export type NotificationViewArgs = {
  visualState: VisualState;
  cancelNotification: CancelNotificationFunc;
  openSettingsScreen: OpenSettingsScreenFunc;
  scheduleNotification: ScheduleNotificationFunc;
  notificationAlertHandler: NotificationAlertHandler;
  scheduleNotificationState: ScheduleNotificationState;
  enableButtonPressedHandler: (pressed: boolean) => void;
};

export enum VisualState {
  // Case A - Show reminder prompt
  A__SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT = 'SHOULD_SHOW_CONTEXT_FOR_OS_PROMPT',
  // Case B - Show go to settings
  B__SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT = 'SHOULD_SHOW_OS_NOTIFS_ARE_OFF_PROMPT',
  // Case C - Show turn on app notification
  C__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_OFF = 'SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_OFF',
  // Case D - Show date picker
  D__SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_ON = 'SHOULD_SHOW_APP_NOTIFS_SETTINGS_WITH_APP_NOTIFS_ON',
  // bad case
  E__EXCEPTION = 'EXCEPTION',
}

export enum NOTIFICATION_TYPE {
  DAILY_REMINDER = 'DAILY_REMINDER',
  SESSION_REMINDER = 'SESSION_REMINDER',
}

export type DailyReminderPayload = {
  type: NOTIFICATION_TYPE.DAILY_REMINDER;
  data: Date;
};

export type SessionsReminderPayload = {
  type: NOTIFICATION_TYPE.SESSION_REMINDER;
  data: Date;
};

export type ScheduleNotification =
  | DailyReminderPayload
  | SessionsReminderPayload;

export type ScheduleNotificationFunc = (payload: ScheduleNotification) => void;

export type CancelNotificationFunc = (payload: ScheduleNotification) => void;

// consider moving isSessionReminderDisabled and isReminderDisabled up because they are reffering to the same value which is osNotificationsAuthorized w
// duplication of value accross multiple notification objects
export type ScheduleNotificationState = {
  [NOTIFICATION_TYPE.DAILY_REMINDER]: {
    date: Date;
    isReminderOn?: boolean;
    isReminderDisabled: boolean;
  };
  [NOTIFICATION_TYPE.SESSION_REMINDER]: {
    date: Date;
    isSessionReminderOn?: boolean;
    isSessionReminderDisabled: boolean;
  };
};
